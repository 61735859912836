export default {
  API_REPORT: 'SubmitReportEducationDepartment/submitEducationReports',
  CREATE_REPORT: 'SubmitReportEducationDepartment/submitReportEducationDepartments',
  CREATE_REPORT_FORM: 'SubmitReportEducationDepartment/submit-report-education-department-form',
  CREATE_REPORT_FILE: 'SubmitReportEducationDepartment/submit-report-education-department-upload-file',
  EDIT_REPORT: 'SubmitReportDepartment/submitReportDepartments',
  DELETE_REPORT: 'SubmitReportEducationDepartment/delete-submit-report-education-department',
  DETAIL_REPORT: 'SubmitReportEducationDepartment/submitReportEducationDepartments/',
  EXPORT_REPORT: 'SubmitReportEducationDepartment/export-report',
  COMBOBOX_TYPE_REPORT: 'ReportClassification/get-combobox-report-type',
  LIST_SUBMIT_REPORT_ELEMENT: 'SubmitReportEducationDepartment/list-submit-report-education-department-job',
  DELETE_SUBMIT_REPORT_ELEMENT: 'SubmitReportEducationDepartment/delete-submit-report-education-department-job',
  CREATE_SUBMIT_REPORT_ELEMENT: 'SubmitReportEducationDepartment/add-submit-report-education-department-job',
  COMBOBOX_TYPEOFPROFESSION: 'TypeOfProfession/get-combobox-treeview',
}
